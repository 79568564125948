import {colours} from "../theme";
import * as d3 from "d3";
import {formatPercent} from "./format";

/*

This file contains all helper functions for creating data overlays for the unit map

*/

const currentYear = (new Date()).getFullYear()

export const dataOverlayToLabelMap = {
  category: "Category",
  turnover: "Turnover",
  leasing_status: "Leasing Status",
  sales_density: "Sales Density",
  rsr: "Rent to Sales Ratio",
  ocr: "Occupancy Cost Ratio",
  lease_expiry: "Lease Expiry",
  break_option: "Break Option",
}

export const getStroke = (d, unitsData, dataOverlay, selectedUnit, otherUnits) => {
  const unitData = unitsData.find(dd => parseInt(dd.unit_id) === parseInt(d.unit_id))
  if (d.unit_id === selectedUnit || otherUnits?.find((u) => d.unit_id === u)) {
    return colours.black
  }
  else if (dataOverlay === "leasing_status") {
    if (!unitData) return colours.blue2;
    if (unitData.leasingStatus === "Redevelopment" || unitData.leasingStatus === "Recon Opportunity") {
      return colours.red
    }
    else {
      return colours.blue2;
    }
  }
  else if (["lease_expiry", "sales_density" , "break_option", "mat_vs_category", "mat_vs_category_monthly", "mat_growth", "mat_growth_monthly", "mat_density", "mat_density_monthly", "mat_ocr"].indexOf(dataOverlay) >= 0) {
    // any overlay where units could be hard to see should use darker borders
    return colours.grey1;
  }
  else if (unitData?.category === "Vacant" && unitData?.unit_type === "storage") {
    return colours.grey4;
  }
  else {
    return colours.white
  }

}

export const getStrokeWidth = (d, unitsData, dataOverlay, selectedUnit, otherUnits) => {
  if (d.unit_id === selectedUnit) {
    return 2
  }
  else if (dataOverlay === "leasing_status") {
    const unitData = unitsData.find(dd => parseInt(dd.unit_id) === parseInt(d.unit_id))
    if (!unitData) return 0.5;
    if (unitData.leasingStatus === "Redevelopment" || unitData.leasingStatus === "Recon Opportunity") {
      return 4
    }
  } else if (otherUnits?.length && otherUnits.find((u) => d.unit_id === u)) {
    return 1
  } else {
    return  0.5
  }
}

export const getDisplay = (d, unitsData, dataOverlay, selectedUnit, otherUnits, isHighlightingUnits) => {
  // TO-DO: Any overlay specific requirements.
  if (isHighlightingUnits) {
    if (d.unit_id === selectedUnit) {
      return "block"
    }
    else if (otherUnits?.length && otherUnits.find((u) => d.unit_id === u)) {
      return "block"
    } else {
      return  "none"
    }
  }
  
  return "block"
}


// Category

export const categoryLabeltoColourMap = {
  "Anchor": colours.mSU,
  "Entertainment & Technology": colours.blue4,
  "Food & Beverage": colours.foodAndBeverage,
  "Footwear & Sports": colours.footwear,
  "Leisure": colours.leisure,
  "Gifts, Toys & Cards": colours.teal,
  "Health & Beauty": colours.healthAndBeauty,
  "Jewellery & Accessories": colours.jewellery,
  "Ladies Fashion": colours.ladiesFashion,
  "Mens Fashion": colours.mensFashion2,
  "Mixed Fashion": colours.mixedFashion,
  "Specialist": colours.specialist,
  "Mall Kiosk": colours.teal, // TODO,
  "Miscellaneous": colours.mSU, // TODO,
  "Storage": colours.storage,
  "Kiosk" : colours.kiosk, // Pradera asked for a brighter colour, sorry Matt.
  "Null": colours.null,
  "Vacant": colours.white,

// legacy categories
//   "Jewellery": colours.jewellery,
//   "Footwear": colours.footwear,
//   "MSU": colours.mSU,
//   "Mobile Phones": colours.mobilePhones,
}

// Leasing Status

export const leasingStatusLabelToColourMap = {
  "None": colours.pink,
  "Exchanged": colours.green,
  "Completed": colours.green,
  "Solicitors Instructed": colours.yellow,
  "Priority": colours.purple,
  "Short / Medium Term": colours.blue2l,
  "Redevelopment": colours.purple,
  "Recon Opportunity": colours.purple,
  "CVA": "url(#red-stripe)",
  "Admin": "url(#red-stripe)",
}


// Turnover

export const turnoverLabel = (turnover) => {
  if (turnover === null || typeof(turnover) === "undefined" || turnover === 0 || Number.isNaN(turnover) || turnover === "") return "No Data";
  else if (turnover <= 2500000) return '< £2.5m';
  else if (turnover <= 5000000) return '< £5m';
  else if (turnover <= 1000000) return '< £1m';
  else if (turnover <= 10000000) return '< £10m';
  else return '> £10m';
}

export const turnoverLabeltoColourMap = {
  "No Data": colours.null,
  "< £2.5m": colours.density1,
  "< £5m": colours.density2,
  "< £1m": colours.density3,
  "< £10m": colours.density4,
  "> £10m": colours.density5,
}


// sales density

// different unit sizes get different colour thresholds
const sizeToSalesDensityThresholdsMap = {
  // i.e. for units larger than 100,000sqft, <= £150/sqft is bad, <= £275 is ok, <= £500 is good, > £500 is very good
  100000: [150, 275, 500],
  50000: [200, 300, 800],
  20000: [205, 358, 900],
  10000: [210, 385, 1000],
  5000: [225, 400, 1250],
  3000: [250, 450, 1350],
  1500: [275, 502, 4620],
  0: [300, 1144, 4959],
}

const salesDensitySizeThresholds = Object.keys(sizeToSalesDensityThresholdsMap).map(d => parseInt(d)).sort((a, b) => b - a)

export const salesDensityDynamicColourScale = (salesDensity, area) => {
  let threshold = 0;
  for (let i = 0; i < salesDensitySizeThresholds.length; i++) {
    if (area >= salesDensitySizeThresholds[i]) {
      threshold = salesDensitySizeThresholds[i]
      break;
    }
  }
  const domain = sizeToSalesDensityThresholdsMap[threshold]
  if (salesDensity > domain[2]) return colours.green2;
  if (salesDensity < domain[0]) return colours.red3;
  const scale = d3.scaleDiverging().domain(domain).range([colours.red2, colours.yellow, colours.green])
  scale.clamp(true);
  return scale(salesDensity)
}


export const salesDensityColourScale = d3.scaleLinear().domain([0, 1000]).range([colours.white, colours.density5])

export const salesDensityLabeltoColourMap = {
  "No Data": colours.null,
  // "£0 per sqft": salesDensityColourScale(0),
  "£100 per sqft": salesDensityColourScale(100),
  "£250 per sqft": salesDensityColourScale(250),
  "£500 per sqft": salesDensityColourScale(500),
  "£750 per sqft": salesDensityColourScale(750),
  "> £1000 per sqft": salesDensityColourScale(1000),
}

//
// RSR: rent to sales ratio
//

export const rsrLabel = (data) => {
  const rsr = data.rent_to_sales_ratio;
  if (rsr === null || typeof(rsr) === "undefined" || rsr === "" || Number.isNaN(rsr)) return "No Data";
  if (rsr <= 0.05) return "< 5%";
  if (rsr <= 0.1) return "< 10%";
  if (rsr <= 0.15) return "< 15%";
  return "> 15%"
}

export const rsrLabeltoColourMap = {
  "No Data": colours.null,
  "< 5%": colours.green,
  "< 10%": colours.yellow,
  "< 15%": colours.red2,
  "> 15%": colours.red3,
}

// OCR

export const ocrLabel = (data) => {
  const ocr = data.occupancy_cost_ratio;
  if (ocr === null || typeof(ocr) === "undefined" || ocr === "" || Number.isNaN(ocr)) return "No Data";
  if (ocr <= 0.2) return "< 20%";
  if (ocr <= 0.3) return "< 30%";
  if (ocr <= 0.4) return "< 40%";
  return "> 40%"
}

// This returns the maximum value for green and for amber. Red is always greater than the max value
// These came from Tom D @ Pradera

const defaultOCRThresholds = [0.2, 0.3]
export const categoryToOCRThresholds = {
  "Anchor": [0.15, 0.2],
  "Entertainment & Technology": defaultOCRThresholds,
  "Food & Beverage": defaultOCRThresholds,
  "Footwear & Sports": [0.25, 0.3],
  "Leisure": [0.25, 0.3],
  "Gifts, Toys & Cards": defaultOCRThresholds,
  "Health & Beauty": [0.25, 0.3],
  "Jewellery & Accessories": [0.25, 0.35],
  "Kiosk": defaultOCRThresholds,
  "Ladies Fashion": [0.25, 0.3],
  "Mens Fashion": [0.25, 0.3],
  "Mixed Fashion": [0.25, 0.3],
  "Specialist": [0.25, 0.3],
  "Mall Kiosk": defaultOCRThresholds,
  "Miscellaneous": defaultOCRThresholds,
  "Null": defaultOCRThresholds,
  "Vacant": defaultOCRThresholds,

  // legacy category names must be kept in
  "Jewellery": [0.25, 0.35],
  "Footwear": [0.25, 0.3],
  "MSU": [0.15, 0.2],
  "Mobile Phones": defaultOCRThresholds,
  "Storage": defaultOCRThresholds,
  "ATM": defaultOCRThresholds,
}

const ocrColours = [colours.green, colours.yellow, colours.red]

/**
 * Get category specific OCR labels.
 * @param ocr float
 * @param category string
 */
export const ocrLabelDynamic = (ocr, category) => {
  if (ocr === null || typeof(ocr) === "undefined" || ocr === "" || Number.isNaN(ocr)) return { label: "No Data", colour: colours.null };

  const thresholds = Object.keys(categoryToOCRThresholds).includes(category) ? categoryToOCRThresholds[category] : defaultOCRThresholds;
  for (let i = 0; i < thresholds.length; i++) {
    if (ocr <= thresholds[i]) {
      return { label: "<" + formatPercent(thresholds[i]), colour: ocrColours[i] };
    }
  }
  return { label: ">" + formatPercent(thresholds[thresholds.length-1]), colour: ocrColours[ocrColours.length-1] }
}

export const ocrLabeltoColourMap = {
  "No Data": colours.null,
  "Low": colours.green,
  "Medium": colours.yellow,
  "High": colours.red,
}


// lease expiry


export const leaseExpiryLabel = (data) => {

  if (data.tenant.toLowerCase().includes("trafford centre")) return "Vacant, Expired";
  else if (data.end_date === null || typeof (data.end_date) === "undefined" || data.end_date === "") return "Vacant, Temp, Expired";

  const leaseExpiryDate = new Date(data.end_date)
  const leaseExpiryYear = leaseExpiryDate.getFullYear()
  // const today = new Date()
  // const daysToExpiry = (leaseExpiryDate - today) / 24 / 60 / 60 / 1000;

  // a month is 30 days for simplicity
  // if (daysToExpiry <= 0) return 'Vacant, Expired';
  if (leaseExpiryYear < currentYear) return 'Vacant, Expired';
  if (leaseExpiryYear === currentYear) return `${currentYear} Expiry`;
  if (leaseExpiryYear === (currentYear + 1)) return `${currentYear + 1} Expiry`;
  if (leaseExpiryYear === (currentYear + 2)) return `${currentYear + 2} Expiry`;
  else return `${currentYear + 3}+ Expiry`;
}

export const leaseExpiryLabelToColourMap = {
  "No Data": colours.null,
  "Vacant, Expired": colours.plOrange,
  [`${currentYear} Expiry`]: colours.plGreen,
  [`${currentYear + 1} Expiry`]: colours.plYellow,
  [`${currentYear + 2} Expiry`]: colours.plBlue,
  [`${currentYear + 3}+ Expiry`]: colours.pink,
}

// break option

export const breakOptionLabel = (data) => {

  if (data.break_option_type === null || typeof(data.break_option_type) === "undefined") return "No Data";
  if (data.break_option_type.toLowerCase() === "brr") return "Rolling";
  if (data.break_option === null || typeof(data.break_option) === "undefined" || data.break_option === "") return `${currentYear + 3}+ Break / None`;

  // TODO: if the break date has passed, the colour would update based on the next break date or fall away if no more break options

  const breakOptionDate = new Date(data.break_option)
  const breakOptionYear = breakOptionDate.getFullYear()
  // const today = new Date()
  // const daysToBreakOption = (breakOptionDate - today) / 24 / 60 / 60 / 1000;

  // a month is 30 days for simplicity
  if (breakOptionYear < currentYear) return 'Expired';
  else if (breakOptionYear === currentYear) return `${currentYear} Break`;
  else if (breakOptionYear === currentYear + 1) return `${currentYear + 1} Break`;
  else if (breakOptionYear === currentYear + 2) return `${currentYear + 2} Break`;
  else return `${currentYear + 3}+ Break / None`;
}

export const breakOptionLabelToColourMap = {
  "No Data": colours.null,
  "Expired": colours.plOrange,
  "Rolling": colours.purple,
  [`${currentYear} Break`]: colours.plGreen,
  [`${currentYear + 1} Break`]: colours.plYellow,
  [`${currentYear + 2} Break`]: colours.plBlue,
  [`${currentYear + 3}+ Break / None`]: colours.pink,
}



// mat

export const turnoverColourScale = d3.scaleLinear().domain([0, 10000000]).range([colours.white, colours.density5])

const _turnoverGrowthRateColourScale = d3.scaleDiverging().domain([-0.1, 0, 0.1]).range([colours.red2, colours.yellow, colours.green]);
_turnoverGrowthRateColourScale.clamp(true)
export const turnoverGrowthRateColourScale = (value) => {
  if (value < -0.1) return colours.red3;
  if (value > 0.1) return colours.green2;
  return _turnoverGrowthRateColourScale(value)
}
